












import {Vue, Component, Prop} from 'vue-property-decorator';

import DataList from '@/shared/resources/components/data-list/DataList.vue';
import ORMCollection from '@/core/bridge/orm/ORMCollection';
import DocumentsTable from '@/app/modules/documents/components/partials/tables/DocumentsTable.vue';
import AppSettings from '@/app/lib/settings/AppSettings';
import ApiORMQueryBuilderExtended from '@/shared/lib/api/query-builders/ApiORMQueryBuilderExtended';
import DocumentModel from '@/app/modules/documents/models/DocumentModel';
import DocumentTypesEnum from '@/modules/documents/enums/DocumentTypesEnum';

@Component({
  components: {
    DocumentsTable,
    DataList,
  },
})
export default class DocumentsList extends Vue {
  /**
   * Data
   */
  private invoices: ORMCollection = new ORMCollection();
  private errorMessage: string | null = null;

  /**
   * Getters
   */
  private get contractorId(): number | null {
    return AppSettings.user?.contractor?.id || null;
  }

  private get query(): ApiORMQueryBuilderExtended {
    return DocumentModel
      .apiExt()
      .where('types', [DocumentTypesEnum.SALE_INVOICE]);
  }

  /**
   * Handlers
   */
  private onFetch(invoices: ORMCollection) {
    this.invoices = invoices;
  }
}
